@charset "UTF-8";
html, body {
    margin: 0px !important;
    min-height: 100%;
    height: 100%;
}
@media print {
    app-root > * { display: none; }
    app-root app-print-layout { display: block; }
  }
  .week-header{
    padding: 2px 0px 0px 0px !important;
  }
  .dashboard-body:after {
    content: "";
    display: block;
    width: 50px;
    float: right;
    height: 110px;
    position: fixed;
    right: 10px;
    bottom: 10px;
    /*background-image: url(../assets/OTD-logo-white.png);*/
    background-size: 100% auto;
    background-repeat: no-repeat;
    opacity: 0.7;
  }

  .report_description{
    color: #636363;
  }
  .dx-icon-pdffile{
    font-size: 24px !important;
    color: #fb4d23c7;
  }
  .dx-icon-xlsxfile{
    font-size: 24px !important;
    color: #23c14eeb;
  }
  .dx-field-value-static, .dx-field-value:not(.dx-switch):not(.dx-checkbox):not(.dx-button) {
    width: 100% !important;
}